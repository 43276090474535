var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: "mdi-scale-balance", title: "Balanço" },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return undefined
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("v-simple-table", {
                attrs: { "fixed-header": "", height: "450px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("thead", [
                          _c(
                            "tr",
                            [
                              _c("th", { staticClass: "text-left" }, [
                                _vm.data.total
                                  ? _c("strong", [
                                      _vm._v(_vm._s(_vm.data.total) + " Amb.")
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._l(
                                _vm.days.filter(function(d) {
                                  return d.visible
                                }),
                                function(day) {
                                  return _c(
                                    "th",
                                    { key: day.id, staticClass: "text-center" },
                                    [_vm._v(" " + _vm._s(day.initials) + " ")]
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.hours, function(hour) {
                            return _c(
                              "tr",
                              { key: hour.id },
                              [
                                _c("td", [_vm._v(_vm._s(hour.start))]),
                                _vm._l(
                                  _vm.days.filter(function(d) {
                                    return d.visible
                                  }),
                                  function(day) {
                                    return _c(
                                      "td",
                                      {
                                        key: day.id,
                                        staticClass: "text-center pa-1"
                                      },
                                      [
                                        _c("v-progress-linear", {
                                          attrs: {
                                            value: _vm.getPercent(hour, day),
                                            color: _vm.getColor(
                                              _vm.getPercent(hour, day)
                                            ),
                                            striped: "",
                                            height: "25"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var value = ref.value
                                                  return [
                                                    _vm.getTotalItems(
                                                      hour,
                                                      day
                                                    ) > 0
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _vm.byPercent
                                                                        ? _c(
                                                                            "span",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticStyle: {
                                                                                    cursor:
                                                                                      "help"
                                                                                  }
                                                                                },
                                                                                "span",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  Math.ceil(
                                                                                    value
                                                                                  )
                                                                                ) +
                                                                                  "% "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  staticStyle: {
                                                                                    cursor:
                                                                                      "help"
                                                                                  }
                                                                                },
                                                                                "span",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.getTotalItems(
                                                                                    hour,
                                                                                    day
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm.byPercent
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getTotalItems(
                                                                          hour,
                                                                          day
                                                                        )
                                                                      ) +
                                                                      " aula(s) "
                                                                  )
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        Math.ceil(
                                                                          value
                                                                        )
                                                                      ) +
                                                                      "% de Ocupação "
                                                                  )
                                                                ])
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  }
                                )
                              ],
                              2
                            )
                          }),
                          0
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("v-switch", {
                staticClass: "ml-1",
                attrs: { inset: "", label: "Exibir percentual" },
                model: {
                  value: _vm.byPercent,
                  callback: function($$v) {
                    _vm.byPercent = $$v
                  },
                  expression: "byPercent"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "5" } },
            [
              _vm.isAnima()
                ? _c(
                    "v-row",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c("sys-select-brand", {
                            attrs: {
                              model: _vm.brand_id,
                              label: "Marca*",
                              "auto-complete": ""
                            },
                            on: {
                              "update:model": function($event) {
                                _vm.brand_id = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("sys-select-campus", {
                        attrs: {
                          model: _vm.campuses,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.campuses,
                          label: "Campus*",
                          multiple: "",
                          "brand-id": _vm.brand_id,
                          "filter-relation": "ambiences"
                        },
                        on: {
                          "update:model": function($event) {
                            _vm.campuses = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("sys-select-block", {
                        attrs: {
                          model: _vm.blocks,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.blocks,
                          label: "Bloco",
                          multiple: "",
                          "campus-id": _vm.campus_ids,
                          "filter-relation": "ambiences",
                          dense: "",
                          "select-all": ""
                        },
                        on: {
                          "update:model": function($event) {
                            _vm.blocks = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("sys-select-ambience-type", {
                        attrs: {
                          model: _vm.ambienceTypes,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.ambienceTypes,
                          label: "Tipos de Ambientes*",
                          multiple: "",
                          "select-all": "",
                          "campus-id": _vm.campus_ids
                        },
                        on: {
                          "update:model": function($event) {
                            _vm.ambienceTypes = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "8" } },
                    [
                      _c("sys-select-work-load-type", {
                        attrs: {
                          model: _vm.workLoadTypes,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.workLoadTypes,
                          label: "Tipo de Carga Horária",
                          multiple: "",
                          "select-all": "",
                          dense: ""
                        },
                        on: {
                          "update:model": function($event) {
                            _vm.workLoadTypes = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("sys-select-status-group", {
                        attrs: {
                          model: _vm.statuses,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.statuses,
                          label: "Status da Turma",
                          multiple: "",
                          dense: ""
                        },
                        on: {
                          "update:model": function($event) {
                            _vm.statuses = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("v-range-slider", {
                        staticClass: "align-center",
                        attrs: {
                          min: _vm.min,
                          max: _vm.max,
                          "hide-details": "",
                          "thumb-label": "always",
                          label: "Capacidade"
                        },
                        model: {
                          value: _vm.capacity,
                          callback: function($$v) {
                            _vm.capacity = $$v
                          },
                          expression: "capacity"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("v-range-slider", {
                        staticClass: "align-center",
                        attrs: {
                          min: _vm.min,
                          max: _vm.max,
                          "hide-details": "",
                          "thumb-label": "always",
                          label: "Alunos"
                        },
                        model: {
                          value: _vm.students,
                          callback: function($$v) {
                            _vm.students = $$v
                          },
                          expression: "students"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2 white--text",
                          attrs: { loading: _vm.loading, color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.getData()
                            }
                          }
                        },
                        [
                          _vm._v(" Pesquisar "),
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v(" search ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }